<template>
  <Loading v-if="initLoading" />
  <div class="sign" v-else>
    <Header isNoBg v-if="!$route.query.platform">签到</Header>
    <div class="bg" :style="{ top: $route.query.platform ? 0 : '50px' }">
      <div class="head">
        <div class="headItem">
          <div class="img">{{ signInfo.continusDays }}</div>
          <div class="title">连续签到天数</div>
        </div>
        <div class="headItem">
          <div class="img">{{ signInfo.monthTotalDays }}</div>
          <div class="title">本月累计签到天数</div>
        </div>
      </div>
      <div class="content">
        <div class="date">
          <div class="header">
            <div class="left">仅显示最近一个月的签到情况</div>
            <div class="right" @click="isShow = !isShow">
              {{ !isShow ? '展开' : '收起' }}
            </div>
          </div>
          <div class="week">
            <div class="weekItem" v-for="item in weekList" :key="item">
              {{ item }}
            </div>
          </div>
          <div class="days">
            <div class="dayItem" v-for="(item, key) in days" :key="key">
              <span
                :class="
                  item.future
                    ? 'future'
                    : item.active
                    ? 'active'
                    : item.target
                    ? 'target'
                    : null
                "
              >
                {{ item.day }}
                <i v-if="item.day === 1">{{ item.month }}月</i>
              </span>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <div class="btn active" v-if="!isSign" @click="sign">签到</div>
          <div class="btn" v-else>已签到</div>
        </div>
      </div>
    </div>

    <div class="box" v-if="signOk">
      <div class="pop">
        <img src="../../../assets/images/sign.png" alt="" />
        <div class="money" v-if="signOkInfo.popup == 1">
          <span>{{ signOkInfo.atBonus || 0 }}</span>
        </div>
        <div class="money" v-else>
          ¥
          <span>{{ signOkInfo.atBonus || 0 }}</span>
        </div>
        <div class="title" v-if="signOkInfo.popup == 1">获得竞猜交子!</div>
        <div class="title" v-else>获得现金奖励!</div>
        <div class="info">已连续签到{{ signOkInfo.continusDays || 0 }}天!</div>
        <div class="btn" @click="close">确定</div>
        <div class="title1" v-if="signOkInfo.popup == 1">*仅限当天使用</div>
      </div>
    </div>

    <!-- 观看视频获得奖励-->
    <div class="box1" v-if="showVideo">
      <div class="bgImg">
        <img src="../../../assets/images/hbBg.png" alt="" />
        <div class="content">
          <div class="top">
            <span>¥</span>
            <span>8.8</span>
            <span>元</span>
            <div class="tag">最高可得</div>
          </div>
          <div class="middlle">观看视频获得额外现金奖励</div>
          <div class="bottom">
            <div class="tab" @click="goToApp">去看视频</div>
            <div class="tab1" @click="showVideo = false">放弃福利</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { addBridgeForAndroidWebView } from 'sdbridge-android';
const weekMap = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 0,
};
export default {
  data() {
    return {
      signOk: false,
      showVideo: false,
      signOkInfo: {},
      initLoading: true,
      weekList: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      isShow: false,
      months: moment().daysInMonth(), // 当前月天数
      prevMonths: moment().subtract(1, 'months').daysInMonth(), // 上个月天数
      day: moment().format('DD'), // 当天几号
      week: weekMap[moment().format('d')], // 当前周几
      targetMonth: moment().format('MM'), // 当前月份
      targetYear: moment().format('YYYY'), // 当前月份
      prevMonth: moment().subtract(1, 'months').format('MM'),
      signInfo: { signList: [] },
      isSign: false,
      token: null,
      bridge: null,
      isAndroid:
        navigator.userAgent.indexOf('Android') > -1 ||
        navigator.userAgent.indexOf('Adr') > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    this.getSignInfo();
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log('TypeScript正在尝试挂载');
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          'window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!',
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log('window.WebViewJavascriptBridge 挂载成功！！');
    }
  },
  methods: {
    goToApp() {
      if (this.isiOS) {
        this.bridge.callHandler('jumptoapp', { key: 11 }, function (response) {
          console.log(JSON.stringify(response));
        });
      }
    },
    close() {
      this.signOk = false;
      if (!this.$route.query.platform) return;
      if (this.isiOS) {
        this.bridge.callHandler('jumptoapp', { key: 11 }, function (response) {
          console.log(JSON.stringify(response));
        });
        // this.showVideo = true;
      } else if (this.isAndroid) {
        // eslint-disable-next-line no-undef
        control.jumptoapp(JSON.stringify({ key: 11 }));
      }
    },
    async sign() {
      const res = await this.$api.userSignDays({
        token: this.token,
      });
      if (res.code === 0) {
        this.signOkInfo = res.data;
        this.signOk = true;
        this.getSignInfo();
      } else {
        this.$toast(res.msg);
      }
    },
    async getSignInfo() {
      try {
        const e = await this.$api.userSignList({ token: this.token });
        if (e.code === 0) {
          this.signInfo = e.data;
          if (moment().format('YYYY-MM-DD') === e.data.lastCheckin) {
            this.isSign = true;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
      }
    },
  },
  computed: {
    days() {
      const week = this.week;
      let key = 6 - week;
      let list = [];
      if (this.isShow) {
        let targetYear = this.targetYear;
        let day = Number(this.day);
        let day1 = Number(this.day);
        let targetMonth = this.targetMonth;
        let beyond = 0; // 当前日期提前30天之前的 标记灰色
        // 当天和右侧
        for (let i = 0; i <= key; i++) {
          if (day === Number(this.day)) {
            list[Number(week) + i] = {
              day: day,
              monthAndDay: moment(
                `${targetYear}-${targetMonth}-${day < 10 ? '0' + day : day}`,
              ).format('YYYY-MM-DD'),
              target: true,
              month: targetMonth,
            };
          } else {
            list[Number(week) + i] = {
              day: day,
              monthAndDay: moment(
                `${targetYear}-${targetMonth}-${day < 10 ? '0' + day : day}`,
              ).format('YYYY-MM-DD'),
              month: targetMonth,
              future: true,
            };
          }
          if (day + 1 > this.months) {
            let newTargetMoth = Number(targetMonth) + 1;
            if (newTargetMoth > 12) {
              newTargetMoth = 1;
              targetYear = moment().add(1, 'Q').format('YYYY');
            }
            day = 0;
            targetMonth =
              newTargetMoth > 9 ? newTargetMoth : '0' + newTargetMoth;
          }
          day++;
        }
        // 当天左侧
        targetMonth = this.targetMonth;
        targetYear = this.targetYear;
        for (let i = week - 1; i >= 0; i--) {
          day1--;
          if (day1 <= 0) {
            day1 = this.prevMonths;
            targetMonth = this.prevMonth;
            if (targetMonth == 12) {
              targetYear = moment().add(-1, 'Q').format('YYYY');
            }
          }
          list[i] = {
            day: day1,
            monthAndDay: moment(
              `${targetYear}-${targetMonth}-${day1 < 10 ? '0' + day1 : day1}`,
            ).format('YYYY-MM-DD'),
            month: targetMonth,
          };
          beyond++;
        }
        let days = 28;
        if (week <= 1) {
          days = 35;
        }
        for (let i = 0; i < days; i++) {
          day1--;
          beyond++;
          if (day1 <= 0) {
            let newTargetMoth = Number(targetMonth) - 1;
            if (newTargetMoth < 1) {
              newTargetMoth = 12;
              targetYear = moment().add(-1, 'Q').format('YYYY');
            }
            let prevMoth =
              newTargetMoth > 9 ? newTargetMoth : '0' + newTargetMoth;
            day1 = moment(`${targetYear}-${prevMoth}`, 'YYYY-MM').daysInMonth();
            targetMonth = prevMoth;
          }
          if (beyond > 30) {
            list.unshift({
              day: day1,
              monthAndDay: moment(
                `${targetYear}-${targetMonth}-${day1 < 10 ? '0' + day1 : day1}`,
              ).format('YYYY-MM-DD'),
              future: true,
              month: targetMonth,
            });
          } else {
            list.unshift({
              day: day1,
              monthAndDay: moment(
                `${targetYear}-${targetMonth}-${day1 < 10 ? '0' + day1 : day1}`,
              ).format('YYYY-MM-DD'),
              month: targetMonth,
            });
          }
        }
      } else {
        let targetYear = this.targetYear;
        let day = Number(this.day);
        let day1 = Number(this.day);
        let targetMonth = this.targetMonth;
        // 当天和右侧
        for (let i = 0; i <= key; i++) {
          if (day === Number(this.day)) {
            list[Number(week) + i] = {
              day: day,
              monthAndDay: moment(
                `${targetYear}-${targetMonth}-${day < 10 ? '0' + day : day}`,
              ).format('YYYY-MM-DD'),
              target: true,
              month: targetMonth,
            };
          } else {
            list[Number(week) + i] = {
              day: day,
              monthAndDay: moment(
                `${targetYear}-${targetMonth}-${day < 10 ? '0' + day : day}`,
              ).format('YYYY-MM-DD'),
              future: true,
              month: targetMonth,
            };
          }
          if (day + 1 > this.months) {
            let newTargetMoth = Number(targetMonth) + 1;
            if (newTargetMoth > 12) {
              newTargetMoth = 1;
              targetYear = moment().add(1, 'Q').format('YYYY');
            }
            day = 0;
            targetMonth =
              newTargetMoth > 9 ? newTargetMoth : '0' + newTargetMoth;
          }
          day++;
        }
        // 当天左侧
        targetYear = this.targetYear;
        targetMonth = this.targetMonth;
        for (let i = week - 1; i >= 0; i--) {
          day1--;
          if (day1 <= 0) {
            day1 = this.prevMonths;
            targetMonth = this.prevMonth;
            if (targetMonth == 12) {
              targetYear = moment().add(-1, 'Q').format('YYYY');
            }
          }
          list[i] = {
            day: day1,
            monthAndDay: moment(
              `${targetYear}-${targetMonth}-${day1 < 10 ? '0' + day1 : day1}`,
            ).format('YYYY-MM-DD'),
            month: targetMonth,
          };
        }
      }
      for (let i = 0; i < list.length; i++) {
        const target = list[i];
        for (let j = 0; j < this.signInfo.signList.length; j++) {
          const element = this.signInfo.signList[j];
          if (target.monthAndDay === element.signDate) {
            target.active = true;
          }
        }
      }
      return list;
    },
  },
};
</script>

<style lang="less" scoped>
.sign {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: linear-gradient(#ffe077, #f4d055);
  .bg {
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    .head {
      display: flex;
      align-items: center;
      border-bottom-left-radius: 120px;
      border-bottom-right-radius: 120px;
      background: linear-gradient(#ffe077, #f4d055);
      margin: 0 -50px;
      padding: 0 50px;
      .headItem {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 25px 0 40px;
        .img {
          width: 60px;
          height: 60px;
          background-image: url(../../../assets/images/dayBg.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 23px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #d3a70f;
        }
        .title {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #4b3b02;
          margin-top: 15px;
        }
      }
    }
    .content {
      flex: 1;
      background: #f8f8f8;
      padding: 0 15px;
      .date {
        background: #fff;
        margin-top: -25px;
        border-radius: 5px;
        overflow: hidden;
        padding-bottom: 10px;
        .header {
          background: #f5a92d;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 15px 15px 15px 20px;
          box-shadow: 0px 3px 5px 0px rgba(23, 18, 0, 0.04);
          .left {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffdfaa;
          }
          .right {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            position: relative;
            padding-right: 10px;
          }
        }
        .week {
          display: flex;
          align-items: center;
          padding: 15px 10px;
          .weekItem {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #4b3b02;
          }
        }
        .days {
          display: flex;
          padding: 0 10px;
          align-items: center;
          flex-wrap: wrap;
          .dayItem {
            width: 14.28%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px 0;
            span {
              display: flex;
              width: 28px;
              height: 28px;
              align-items: center;
              justify-content: center;
              font-size: 13px;
              border-radius: 50%;
              color: #666666;
              background: #f8f8f8;
              position: relative;
              &.active {
                color: #fff;
                background: #f5a92d;
              }
              &.future {
                background: #f8f8f8;
                color: #eeeeee;
              }
              &.target {
                border: 1px solid #f5a92d;
              }
              i {
                display: inline-block;
                position: absolute;
                font-size: 12px;
                color: #d3a70f;
                right: -10px;
                top: -10px;
                letter-spacing: -1px;
              }
            }
          }
        }
      }
    }
    .btnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 56px;
      .btn {
        width: 223px;
        height: 44px;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ececec;
        box-shadow: 0px 7px 8px 1px rgba(214, 214, 214, 0.17);
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #f5a92d;
        &.active {
          box-shadow: 0px 7px 8px 1px rgba(233, 186, 26, 0.17);
          background: linear-gradient(11deg, #f5aa2d 0%, #fedf74 100%);
          color: #ffffff;
        }
      }
    }
  }
  .box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    .pop {
      background: #fffdf3;
      width: 70%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding-bottom: 25px;
      border-radius: 5px;
      img {
        width: 120px;
        margin-top: -25px;
      }
      .money {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #a53d1c;
        span {
          font-size: 20px;
        }
      }
      .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #b58567;
        margin: 8px 0 15px;
      }
      .info {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #d64b1a;
        margin-bottom: 15px;
      }
      .title1 {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: red;
        margin: 10px 0 0;
      }
      .btn {
        width: 145px;
        height: 35px;
        background: linear-gradient(175deg, #fdeb68 0%, #f5b749 100%);
        border-radius: 3px;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #a53d1c;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }
  }
  .box1 {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    .bgImg {
      width: 80%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        max-width: 100%;
      }
      .content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .top {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-top: 35px;
          position: relative;
          .tag {
            position: absolute;
            right: 30px;
            top: 0;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #b65622;
            background: #fff7d1;
            border-radius: 10px 10px 10px 0px;
            padding: 5px 10px;
          }
          span {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #f02006;
            &:nth-child(2) {
              font-size: 60px;
              line-height: 60px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #f02006;
              margin: 0 20px;
            }
          }
        }
        .middlle {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fef1d2;
          text-align: center;
          margin-top: 140px;
        }
        .bottom {
          margin-top: 30px;
          text-align: center;
          .tab {
            width: 160px;
            height: 37px;
            background: linear-gradient(0deg, #feb644, #feeab1);
            border-radius: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #b32119;
            margin: 0 auto 18px;
          }
          .tab1 {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 400;
            text-decoration: underline;
            color: #fef1d2;
          }
        }
      }
    }
  }
}
</style>
